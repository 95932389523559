<template>
    <template-layout>
        <template slot="drawers">
        </template>

        <template slot="content">
            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Movimientos de Inventario</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-text>
                                <v-data-table
                                    :items="movements"
                                    :loading="isLoading"
                                    :headers="headers"
                                    :search="search"
                                    :items-per-page="-1"
                                    sort-by="name"
                                >
                                    <template v-slot:item.inventoryItem="{ item }">
                                        {{ item.inventoryItem.name | capitalize }}
                                    </template>

                                    <template v-slot:item.createdAt="{ item }">
                                        {{ item.createdAt | moment('DD/MM/Y h:mm:ss a') }}
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout';

    export default {
        components: {
            TemplateLayout
        },

        data() {
            return {
                selectedInventory: null,

                defaultFilters: {},
                filters: {},

                search: '',

                pagination: {
                    page: 1,
                    itemsPerPage: 100
                },

                headers: [
                    { text: 'Item', value: 'inventoryItem' },
                    { text: 'Cantidad', value: 'quantity' },
                    { text: 'Balance', value: 'balance' },
                    { text: 'Fecha', value: 'createdAt' },
                    { text: 'Nota', value: 'comment' }
                ]
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['inventory/isLoading']();
            },

            movements() {
                return this.$store.getters['inventory/getAllMovements']();
            }
        },

        watch: {
            pagination: {
                handler(val) {
                    this.updateRouteQuery();
                },
                deep: true
            },

            filters: {
                handler(val) {
                    this.updateRouteQuery();

                    this.fetchInventory();
                },

                deep: true
            }
        },

        mounted() {
            this.filters = JSON.parse(JSON.stringify(this.defaultFilters));

            this.updateFiltersFromQuery();
            // this.updateRouteQuery();

            this.$store.dispatch('providers/fetchAll');
        },

        methods: {
            updateFiltersFromQuery() {
                let q = { ...this.$route.query };

                for(let key of Object.keys(this.filters)) {
                    if(q[key]) {
                        this.filters[key] = q[key];
                    }
                }

                if(this.$route.query.search) {
                    this.search = this.$route.query.search;
                }
            },

            updateRouteQuery() {
                let q = {};

                q = { ...this.filters };

                if(this.search) {
                    q.search = this.search;
                }

                this.$router.replace({ name: 'inventoryMovementsList', query: q }).catch(() => {});
            },

            async fetchInventory() {
                let filters = {};
                // filters.offset = 0;
                // filters.limit = 1000;

                await this.$store.dispatch('inventory/fetchMovements', filters);
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
